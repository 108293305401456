$(document).ready(function() {

  //определение ширины скролла для фансибокс
  // создадим элемент с прокруткой
  var div = document.createElement('div');
  div.style.overflowY = 'scroll';
  div.style.width = '50px';
  div.style.height = '50px';

// при display:none размеры нельзя узнать
// нужно, чтобы элемент был видим,
// visibility:hidden - можно, т.к. сохраняет геометрию
  div.style.visibility = 'hidden';
  document.body.appendChild(div);
  var scrollWidth = div.offsetWidth - div.clientWidth;
  document.body.removeChild(div);
  /*console.log( scrollWidth );*/

  $(".header__toggle").click(function () {
    $(this).closest(".header").toggleClass("menu-active");
    $(this).closest("body").toggleClass("menu-active");
  });
  $(".menu").click(function () {
    $(this).closest(".header").removeClass("menu-active");
    $(this).closest("body").removeClass("menu-active");
  });

  $(function () {
    $(window).scroll(function () {
      var topHeader = $(document).scrollTop();
      var headerFix = $(".header-page").height();
      /*console.log(hheader2)*/
      if (topHeader < headerFix - 100) {
        $(".header").removeClass("fix");
      }
      else {

        $(".header").addClass("fix");
      }
    });
  });
  //new script
  /*slider home-banner*/
  $('.reviews-items').slick({
    dots: false,
    arrows:true,
    infinite: true,
    autoplay: true,
    autoplaySpeed:2500,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          autoplay:false

        }
      },
    ]
  });
  $('.services-item__foto-box').slick({
    dots: false,
    arrows:false,
    infinite: true,
    autoplay: true,
    autoplaySpeed:2500,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    //fade:true,
  });

  $(".menu").on("click","a.pr", function (event) {
    //отменяем стандартную обработку нажатия по ссылке
    event.preventDefault();

    //забираем идентификатор бока с атрибута href
    var id  = $(this).attr('href'),

      //узнаем высоту от начала страницы до блока на который ссылается якорь
      top = $(id).offset().top;

    //анимируем переход на расстояние - top за 1500 мс

    $('body,html').animate({scrollTop: top - 130}, 1500);


  });

  //services-item__btn
  $(".services-item__btn").on("click", function (event) {
    //отменяем стандартную обработку нажатия по ссылке
    event.preventDefault();

    //забираем идентификатор бока с атрибута href
    var id2  = $(this).attr('href'),

      //узнаем высоту от начала страницы до блока на который ссылается якорь
      top2 = $(id2).offset().top;

    //анимируем переход на расстояние - top за 1500 мс
    $('body,html').animate({scrollTop: top2 - 70}, 1500);
  });


});
















































